/**
 * Copyright 2018-present, Distributed Media Lab, Inc.
 */

import React from 'react'
import PropTypes from 'prop-types'
// MUI Components
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(() => ({
  code: {
    fontSize: '12px',
  },
  pre: {
    background: '#eee',
    border: '1px solid #ccc',
    width: '440px',
    padding: '20px',
    margin: '10px auto',
    textAlign: 'left'
  }
}))

const EmbedCode = (props) => {
  const classes = useStyles(props)
  const { id } = props

  if (!id) return null

  return (
    <pre className={classes.pre}>
      <code className={classes.code}>
        &lt;div<br/>
        &nbsp;&nbsp;data-stackid="{id}"<br/>
        &nbsp;&nbsp;class="dml-widget-container"<br/>
        &gt;&lt;/div&gt;
        <br/>
        &lt;script async src="https://c.go-fet.ch/a/embed.js"&gt;&lt;/script&gt;
      </code>
    </pre>
  )
}

EmbedCode.propTypes = {
  id: PropTypes.string.isRequired
}

export default EmbedCode
