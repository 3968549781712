/**
 * Copyright 2018-present, Distributed Media Lab, Inc.
 */

import React from 'react'
// MUI Components
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(() => ({
  section: {
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
    textAlign: 'left'
  },
  code: {
    fontSize: '12px',
  },
  pre: {
    background: '#eee',
    border: '1px solid #ccc',
    width: '440px',
    padding: '20px',
    margin: '10px auto',
    textAlign: 'left'
  }
}))

const Guide = (props) => {
  const classes = useStyles(props)
  return (
    <section className={classes.section}>
      <Typography variant="h4">
        Implementation Guide
      </Typography>
      <Typography>
        <p>
          The Matchup Editorial Collections are easy to integrate into your site, using a
          couple of lines of html and javascript.
        </p>
        <p>
          Use this tool to generate embed codes for The Matchup's collections.
          Provide an email address (so we know how to contact), the domain that you wish to use the collection on
          and select the collection to use. You can use the same embed code on multiple domains but we recommend
          creating separate embed codes for each domain.
        </p>
        <p>
          Once you have generated an embed code follow the instructions below to add it to your site. You
          can access any previously created embed codes under the "Existing" tab.
        </p>
      </Typography>
      <Typography variant="h5">
        Option 1: Adding to a single page
      </Typography>
      <Typography>
        <p>
          To add this to a single page or to a single template, copy and paste the generated embed code onto a page to try it out.
        </p>
        <pre className={classes.pre}>
          <code className={classes.code}>
            &lt;div<br/>
            &nbsp;&nbsp;data-stackid="sample"<br/>
            &nbsp;&nbsp;class="dml-widget-container"<br/>
            &gt;&lt;/div&gt;
            <br/>
            &lt;script async src="https://c.go-fet.ch/a/embed.js"&gt;&lt;/script&gt;
          </code>
        </pre>
        </Typography>
      <Typography variant="h5">
        Option 2: Installing site-wide
      </Typography>
      <Typography>
        <p>
          If you are using collections on multiple pages or multiple collections across your site you can
          separate the embed code and place the div tag where the collection should appear and the script tag at the end of the page.
        </p>
        <p>
          Add this to the footer of the site – ideally just before the closing body tag.
        </p>
        <pre className={classes.pre}>
          <code className={classes.code}>
            &lt;script async src="https://c.go-fet.ch/a/embed.js"&gt;&lt;/script&gt;
          </code>
        </pre>
        <p>
          Add the container wherever you want the collection to appear. This should work on an article page, or a section/front page. You can add multiple collections on the same page.
        </p>
        <pre className={classes.pre}>
          <code className={classes.code}>
            &lt;div<br/>
            &nbsp;&nbsp;data-stackid="sample"<br/>
            &nbsp;&nbsp;class="dml-widget-container"<br/>
            &gt;&lt;/div&gt;
          </code>
        </pre>
      </Typography>
      <Typography variant="h5">
        Alternate Layouts
      </Typography>
      <Typography>
        <p>
          Collections are provided with a default layout for mobile and deskptop.
        </p>
        <p>
          Alternate layouts for desktop can be enabled by providing an additional layout attribute on the collection tag.
          Currently we provide two additional options: A 'sidebar' layout that can be used to fit into a 300px wide
          column and a 'oneplus2' layout that can be used in the body of a template.
        </p>
        <pre className={classes.pre}>
          <code className={classes.code}>
            &lt;div<br/>
            &nbsp;&nbsp;data-stackid="sample"<br/>
            &nbsp;&nbsp;data-layout="sidebar"<br/>
            &nbsp;&nbsp;class="dml-widget-container"<br/>
            &gt;&lt;/div&gt;
          </code>
        </pre>

      </Typography>


    </section>
  )
}

export default Guide
