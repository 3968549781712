/**
 * Copyright 2018-present, Distributed Media Lab, Inc.
 */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
// MUI Components
import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
// Local Components
import EmbedCode from './EmbedCode'

const useStyles = makeStyles(() => ({
  section: {
    width: '440px',
    margin: '10px auto'
  },
  alias: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: '20px 0'
  },
  desc: {
    textAlign: 'left',
    paddingRight: '20px',
    flexGrow: 1
  }
}))

const List = (props) => {
  const classes = useStyles(props)
  const { aliases } = props
  const [displayEmbed, setDisplayEmbed] = useState()

  if (!aliases || aliases.length === 0) return null

  const Display = (props) => {
    const { alias } = props
    if (displayEmbed === alias.id) return (
      <div className={classes.alias}>
        <div className={classes.desc}>
          <Typography>
            {alias.name}
          </Typography>
          <Typography>
            Created By: {alias.createdBy}
          </Typography>
        </div>
        <EmbedCode id={alias.id}/>
      </div>
    )

    return (
      <div className={classes.alias}>
        <div className={classes.desc}>
          <Typography>
            {alias.name}
          </Typography>
        </div>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => setDisplayEmbed(alias.id)}
        >
          Show Details
        </Button>
      </div>
    )
  }

  return (
    <section className={classes.section}>
      {aliases.map((alias) =>
        <Display alias={alias} key={alias.id}/>
      )}
    </section>
  )
}

List.propTypes = {
  aliases: PropTypes.array
}

export default List
