/**
 * Copyright 2018-present, Distributed Media Lab, Inc.
 */

const baseUrl = 'https://prod.dmlapi.io/v1'

const API = {
  list: (apiKey) => new Promise((resolve, reject) => {
    const options = {
      headers: {
        'x-api-key': apiKey
      }
    }

    fetch(`${baseUrl}/collection-aliases`, options)
      .then((response) => {
        if (response.status !== 200 || !response.ok) {
          reject('Not authorized')
        }
        return response.json()
      })
      .then((data) => {
        resolve(data)
      })
  }),
  create: (apiKey, newAlias) => new Promise((resolve, reject) => {
    const options = {
      method: 'POST',
      headers: {
        'x-api-key': apiKey
      }, 
      body: JSON.stringify(newAlias)
    }

    fetch(`${baseUrl}/collection-aliases`, options)
      .then((response) => {
        if (response.status !== 200 || !response.ok) {
          console.error('Api.Create Failed', response)
          reject('Create Failed')
        }
        return response.json()
      })
      .then((data) => {
        resolve(data)
      })
  })
}

export default API