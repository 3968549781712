/**
 * Copyright 2018-present, Distributed Media Lab, Inc.
 */

import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
// MUI Components
import AppBar from '@material-ui/core/AppBar'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
// Local components
import API from './components/Api'
import Guide from './components/Guide'
import List from './components/List'
import TabPanel from './components/TabPanel'
import Wizard from './components/Wizard'
import './App.css'
// Make this generic
import logo from './matchup-icon-512.png'

// 31,35,90

const App = () => {
  const [selectedTab, setSelectedTab] = useState(0)
  const [apiKey, setApiKey] = useState()
  const [authed, setAuthed] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [reload, setReload] = useState(false)
  const [aliases, setAliases] = useState([])

  // Auth
  useEffect(() => {
    const url = new URL(window.location)
    if (url.hash) {
      console.log('Setting api key', url.hash)
      setApiKey(url.hash.replace('#', ''))
    } else {
      setLoaded(true)
    }
  }, [])

  useEffect(() => {
    console.log('List with apikey', apiKey)
    if (apiKey) {
      API.list(apiKey)
        .then((response) => {
          console.log(response)
          setAuthed(true)
          setLoaded(true)
          setAliases(response.items)
        })
        .catch((error) => {
          console.error(error)
          setLoaded(true)
        })
    }
  }, [apiKey, reload])

  if (!loaded) return (
    <div className="loading">
      <header className="loading-header">
        <img src={logo} className="loading-logo" alt="logo" />
      </header>
    </div>
  )

  if (!authed) return (
    <div className="App">
      <header className="matchup">The Matchup</header>
      <section>
        Failed authorization. Please contact support support@distributedmedialab.com
      </section>
    </div>
  )

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const displayIntro = aliases.length === 0
  return (
    <div className="App">
      <Helmet>
        <title>The Matchup</title>
        <meta name="description" content="The Matchup" />
        <link rel="icon" href="/logos/matchup-icon-small.png" />

      </Helmet>

      <header className="matchup">The Matchup</header>

      <AppBar position="static">
        <Tabs
          value={selectedTab}
          onChange={(e,t) => setSelectedTab(t)}
          aria-label="Tabs"
          centered
        >
          <Tab label="Create" {...a11yProps(0)} />
          <Tab label="Existing" {...a11yProps(1)} />
          <Tab label="Guide" {...a11yProps(1)} />
        </Tabs>
      </AppBar>

      <TabPanel value={selectedTab} index={0}>
        <Wizard apiKey={apiKey} reload={setReload} displayIntro={displayIntro} />
      </TabPanel>

      <TabPanel value={selectedTab} index={1}>
        <List aliases={aliases} />
      </TabPanel>

      <TabPanel value={selectedTab} index={2}>
        <Guide />
      </TabPanel>
    </div>
  )
}

export default App
