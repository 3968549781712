/**
 * Copyright 2018-present, Distributed Media Lab, Inc.
 */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
// MUI Components
import { makeStyles } from '@material-ui/styles'
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core'
// Local Components
import API from './Api'
import EmbedCode from './EmbedCode'
import collections from './collections.json'

const useStyles = makeStyles(() => ({
  select: {
    width: '400px'
  },
  intro: {
    margin: '0 auto',
    maxWidth: '600px'
  },
  caveat: {
    color: '#666'
  }
}))


const Wizard = (props) => {
  const classes = useStyles(props)
  const { apiKey, displayIntro, reload } = props
  const [domain, setDomain] = useState()
  const [email, setEmail] = useState()
  const [team, setTeam] = useState()
  const [submitted, setSubmitted] = useState(false)
  const [alias, setAlias] = useState()

  const handleSubmit = () => {
    // Find the collection details
    const collection = collections.find((c) => c.id === team)
    const newAlias = {
      createdBy: email,
      name: `${domain}: ${collection.label}`,
      collectionId: collection.id
    }
    // Disable the form for repeat requests
    setSubmitted(true)
    // Create the alias
    API.create(apiKey, newAlias)
      .then((response) => {
        // Display the newly created alias
        setAlias(response)
        // Trigger the app to update the list of existing collections
        reload(true)
      })
      .catch((error) => {
        // Something went wrong
        setSubmitted(false)
      })
  }

  const handleCreateAnother = () => {
    setDomain()
    setTeam()
    setSubmitted(false)
  }

  return (
    <section>
      {displayIntro && (
        <Typography className={classes.intro}>
          <p>
            Please use the form below to create an embed code. Please provide an
            email address and domain name to help us understand how these are being used.
          </p>
          <p>
          Any embed codes that
          you create will be saved to the "EXISTING" tab.
          </p>
          <p>
          The "GUIDE" tab provides instructions for embedding this in your site.
          </p>
        </Typography>
      )}
      <div className="wizard-form">
        <div>
          <TextField
            onChange={(e) => setEmail(e.target.value)}
            value={email || ''}
            label="Your email address"
            fullWidth
            required
            disabled={submitted}
          />
        </div>
        <div>
          <TextField
            onChange={(e) => setDomain(e.target.value)}
            value={domain || ''}
            label="Domain to embed on"
            fullWidth
            required
            disabled={submitted}
          />
        </div>
        <div>
          <FormControl>
            <InputLabel id="team-select-label">
              Select a team/league
            </InputLabel>

            <Select
              labelId="team-select-label"
              id="team-select"
              value={team || ''}
              className={classes.select}
              onChange={(e) => setTeam(e.target.value)}
              disabled={submitted}
            >
              {collections.map((t) => (
                <MenuItem value={t.id} key={t.id} disabled={t.disabled || false}>
                  {t.label}
                </MenuItem>
              ))}
            </Select>
            <Typography className={classes.caveat}>
              Greyed out teams are currently unavailable.
            </Typography>
          </FormControl>
        </div>
        { !alias && (
          <div>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={submitted}
            >
              Generate embed code
            </Button>
          </div>
        )}
      </div>
      { alias && (
        <>
          <Typography>
            This embed code has been saved to your existing embed codes.
          </Typography>
          <EmbedCode
            id={alias.id}
          />
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={handleCreateAnother}
          >
            Create another?
          </Button>
        </>
      )}
    </section>
  )
}

Wizard.propTypes = {
  apiKey: PropTypes.string.isRequired,
  displayIntro: PropTypes.bool.isRequired,
  reload: PropTypes.func.isRequired
}

export default Wizard
